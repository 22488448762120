<script>
export default {
  name: "crypto-form",
  data() {
    return {
      form: {
        transfer_to: '1',
        amount: null,
      }
    };
  },
  methods: {
    submit() {
      this.$router.push({ name: 'deposit-cryptocurrency' });
    }
  },
}
</script>

<template>
  <div>
    <b-form-group
        class="mb-3"
        label="Transfer to"
    >
      <b-form-select
          v-model="form.transfer_to"
          :options="[{value: '1', text: 'Real 36023 BTC'}]"
      />
    </b-form-group>

    <div class="row align-items-baseline mb-3">
      <div class="col-md-6">
        <b-form-group
            class="mb-3 text-muted"
            label="Amount of payment"
        >
          <b-form-input
              v-model="form.amount"
              type="text"
              placeholder="Amount of payment"
          />
        </b-form-group>
      </div>

      <div class="col-md-6">
        <button type="button" class="btn btn-soft-secondary btn-sm btn-rounded me-2 mt-1 mb-1" @click="form.amount = 50">
          50 USD
        </button>

        <button type="button" class="btn btn-soft-secondary btn-sm btn-rounded me-2 mt-1 mb-1" @click="form.amount = 100">
          100 USD
        </button>

        <button type="button" class="btn btn-soft-secondary btn-sm btn-rounded me-2 mt-1 mb-1" @click="form.amount = 250">
          250 USD
        </button>

        <button type="button" class="btn btn-soft-secondary btn-sm btn-rounded mt-1 mb-1" @click="form.amount = 1000">
          1000 USD
        </button>
      </div>
    </div>

    <div class="text-center">
      <button type="button" class="btn btn-dark btn-sm w-lg" @click="submit">Continue</button>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { validationMixin } from '@/mixins/form/validation';
import Multiselect from '@vueform/multiselect';
import { mapGetters } from 'vuex';

const REQUIRED_FIELDS = ['first_name', 'last_name', 'address', 'country_id', 'region', 'post_code', 'phone', 'city'];

export default {
  name: 'card-form',
  mixins: [validationMixin],
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  props: {
    accounts: {
      type: Array,
      required: true,
    },
    loadingAccounts: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: {
        profile: false,
        submit: false,
      },
      form: {
        account: null,
        amount: null,
      },
      profile: null,
      REQUIRED_FIELDS,
      defaultAmounts: [250, 500, 1000],
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountsMap']),
    isRequiredFilled() {
      return REQUIRED_FIELDS.every((field) => this.profile?.[field]);
    },
  },
  watch: {
    // preselect account if one real exists
    accounts(newAccounts) {
      if (!this.form.account && newAccounts.length > 0) {
        this.form.account = newAccounts[0].value;
        this.$refs.form.setFieldValue('account', newAccounts[0].value);
      }
    },
  },
  methods: {
    loadProfile() {
      this.loading.profile = true;

      this.axios
        .get('/profile')
        .then(({ data }) => {
          if (data.country) {
            data.country_id = data.country.id;
          }

          this.profile = data;

          if (!this.isRequiredFilled) {
            this.showFillProfileModal();
          }
        })
        .finally(() => {
          this.loading.profile = false;
        });
    },
    submit(values, actions) {
      // eslint-disable-next-line
      this.loading.submit = true;

      this.axios
        .post(`/accounts/${this.form.account}/payment`, this.form)
        .then(() => {
          // if (data.method === 'GET') {
          //   window.open(data.url, '_blank').focus();
          // }

          this.$notify({
            type: 'success',
            text: this.$t('Deposit.RequestProcessed'),
          });

          this.$store.dispatch('accounts/getAccounts', true);
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
        })
        .finally(() => (this.loading.submit = false));
    },
    showFillProfileModal() {
      Swal.fire({
        title: this.$t('Deposit.Attention'),
        html:
          "<div class='avatar-md mx-auto'>" +
          "<span class='avatar-title rounded-circle bg-warning bg-opacity-10 text-warning font-size-28'>" +
          "<i class='bx bx-meh'></i>" +
          ' </span>' +
          '</div>' +
          '<p class="text-muted mt-3 mb-3 font-size-14">' +
          this.$t('Deposit.FillRequiredFields') +
          '</p>',
        showCloseButton: true,
        showCancelButton: true,
        padding: '0 20px 80px 20px',
        confirmButtonText: this.$t('Deposit.MyProfile'),
        cancelButtonText: this.$t('Common.Cancel'),
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-dark btn-sm w-lg me-2 mb-2',
          cancelButton: 'btn btn-outline-dark btn-sm w-lg me-2 mb-2',
          container: 'swal-padding',
        },
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.$router.push({ name: 'personal' });
        }
      });
    },
  },
  mounted() {
    this.loadProfile();
  },
};
</script>

<template>
  <b-overlay :show="loading.submit || loading.profile || loadingAccounts" spinner-variant="primary" rounded="sm">
    <Form @submit="submit" ref="form">
      <Field name="account" rules="required" :label="$t('Deposit.CardForm.TransferTo')" v-slot="{ errors, field }">
        <b-form-group class="mb-3" :label="$t('Deposit.CardForm.TransferTo')">
          <Multiselect
            v-model="form.account"
            :key="accounts.length"
            :options="accounts"
            :label="'text'"
            :rtl="$i18n.locale === 'ar'"
            :class="{ 'is-invalid': errors.length }"
            searchable
            v-bind="field"
          />

          <ErrorMessage name="account" class="invalid-feedback" />
        </b-form-group>
      </Field>

      <Field
        name="amount"
        rules="required|min:1"
        :label="$t('Deposit.CardForm.AmountOfPayment')"
        v-slot="{ errors, field, handleChange }"
      >
        <div class="row align-items-center mb-3">
          <div class="col-md-6">
            <b-form-group class="mb-3 text-muted" :label="$t('Deposit.CardForm.AmountOfPayment')" label-for="amount">
              <b-form-input
                id="amount"
                v-model="form.amount"
                type="text"
                :placeholder="$t('Deposit.CardForm.AmountOfPayment')"
                v-maska="{ mask: 'XZ*', tokens: { X: { pattern: /[1-9]/ }, Z: { pattern: /[0-9]/ } } }"
                :class="{ 'is-invalid': errors.length }"
                v-bind="field"
              />

              <ErrorMessage name="amount" class="invalid-feedback" />
            </b-form-group>
          </div>

          <div class="col-md-6">
            <button
              v-for="amount in defaultAmounts"
              :key="amount"
              type="button"
              class="btn btn-soft-secondary btn-sm btn-rounded me-2 mt-1 mb-1"
              @click="
                form.amount = amount;
                handleChange(amount);
              "
            >
              {{ amount }} {{ accountsMap[form.account]?.currency?.key }}
            </button>
          </div>
        </div>
      </Field>

      <div class="text-center">
        <button type="submit" class="btn btn-dark btn-sm w-lg" :disabled="!isRequiredFilled">
          {{ $t('Common.Continue') }}
        </button>
      </div>
    </Form>
  </b-overlay>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import CryptoForm from '@/components/pages/deposit/crypto-form';
import CardForm from '@/components/pages/deposit/card-form';

export default {
  components: {
    Layout,
    PageHeader,
    CryptoForm,
    CardForm,
  },
  data() {
    return {
      title: this.$t('Deposit.Title'),
      loading: false,
      type: 'card',
      accounts: [],
    };
  },
  methods: {
    loadAccounts() {
      this.loading = true;
      this.axios
        .get(`/accounts`)
        .then(({ data }) => {
          this.accounts = data
            .filter((account) => !account.is_demo)
            .map((account) => {
              const type = account.is_demo ? 'Demo' : 'Real';

              return {
                value: account.id,
                text: `${type} ${account.name} ${account.currency.key}`,
              };
            });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.loadAccounts();
  },
  metaInfo() {
    return {
      title: this.$t('Deposit.Title'),
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <b-overlay spinner-variant="primary" rounded="sm">
      <div class="card card-transparent mb-4">
        <div class="row">
          <div class="col-12">
            <div class="bg-white">
              <div class="form-title bordered">
                <div class="row justify-content-center">
                  <div class="col-xl-8 col-lg-10">
                    {{ $t('Deposit.SelectType') }}
                  </div>
                </div>
              </div>

              <div class="row pt-3 pb-3 mb-4 justify-content-center">
                <div class="col-xl-8 col-lg-10">
                  <div class="row">
                    <!-- TODO: uncomment when crypto deposit will be ready -->
                    <!--div class="col-sm-6 col-12">
                      <div class="mb-3">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="currency" class="card-radio-input" value="crypto" v-model="type" />

                          <div class="card-radio">
                            <div>
                              <i class="bx bx-bitcoin font-size-24 text-dark align-middle me-2"></i>
                              <span>{{ $t('Deposit.CryptoCurrency') }}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div-->

                    <div class="col-sm-12 col-12">
                      <div class="mb-3">
                        <label class="card-radio-label mb-2">
                          <input type="radio" name="currency" class="card-radio-input" value="card" v-model="type" />

                          <div class="card-radio">
                            <div>
                              <i class="bx bx-credit-card font-size-24 text-dark align-middle me-2"></i>
                              <span>{{ $t('Deposit.Card') }}</span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10 pb-4">
                  <crypto-form v-if="type === 'crypto'" :accounts="accounts" />

                  <card-form v-if="type === 'card'" :accounts="accounts" :loading-accounts="loading" />
                </div>
              </div>
            </div>
          </div>

          <!-- Hidden unil no texts -->
          <div class="d-none col-xl-3 col-12 description">
            <div class="form-title mb-3">General information on payments</div>

            <p class="mb-5">
              Depositing, withdrawal and transferring funds operations are made according to the company's rates
              relevant as of the date of receipt of applications.
            </p>

            <div class="form-title mb-3">FAQ</div>

            <div class="accordion">
              <div v-for="index in 3" :key="index" class="faq-item">
                <button class="accordion-button fw-medium" type="button" v-b-toggle="`faq-${index}`">
                  {{ index }}. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </button>

                <b-collapse :id="`faq-${index}`" accordion="faq" role="tabpanel">
                  <b-card-body>
                    <b-card-text
                      >Depositing, withdrawal and transferring funds operations are made according to the company's
                      rates relevant as of the date of receipt of applications.</b-card-text
                    >
                  </b-card-body>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped lang="scss">
.accordion-button {
  color: #13172b;
  font-weight: 400;
}

.description {
  > * {
    @media (max-width: 1200px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
</style>
